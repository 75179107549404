<template>
  <div class="home" v-if="!isGettingCondition">
    <pickup class="home__pickup" @setFinishedGetting="setFinishedGetting"></pickup>
    <watching class="home__watching" @setFinishedGetting="setFinishedGetting"></watching>
    <update class="home__update" @setFinishedGetting="setFinishedGetting"></update>
    <condition v-if="openConditionDialog || isOldCondition" @setConditionDialog="setConditionDialog"></condition>
    <processing v-if="!finishedGetting"></processing>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import Condition from '@/components/home/condition'
import Pickup from '@/components/home/pickup'
import Watching from '@/components/home/watching'
import Update from '@/components/home/update'
import Processing from '@/components/common/processing'

export default {
  components: { Condition, Pickup, Watching, Update, Processing },
  data () {
    return {
      // 表示に必要な情報を取得中かどうか
      isGetting: {
        pickup: true,
        watching: true,
        update: true
      },
      // コンディションダイアログを表示中かどうか
      openConditionDialog: false
    }
  },
  computed: {
    /**
     * @return {Object} 最新のコンディション情報
     * nullの場合は、コンディション情報がなし
     * 空オブジェクトの場合は、情報の取得中
     */
    condition () {
      return this.getCondition()()
    },
    /**
     * @return {Boolean} 表示に必要な情報を取得済みかどうか
     */
    finishedGetting () {
      return Object.values(this.isGetting).filter(value => value).length === 0
    },
    /**
     * @return {Boolean} 最新のコンディション情報を取得中かどうか
     */
    isGettingCondition () {
      return this.condition !== null && Object.keys(this.condition).length === 0
    },
    /**
     * @return {Boolean} 取得したコンディション情報が古いかどうか
     */
    isOldCondition () {
      // 今日の日付
      const today = moment().hour(0).minutes(0).second(0).toDate()
      if (this.isGettingCondition) {
        return false
      } else if (this.condition === null) {
        return true
      } else {
        const createdAt = this.condition.createdAt.toDate === undefined ? this.condition.createdAt : this.condition.createdAt.toDate()
        return createdAt < today
      }
    }
  },
  methods: {
    ...mapGetters('condition', ['getCondition']),
    /**
     * 表示に必要な情報が取得済みかどうか、を設定する
     * @param {String} type pickup, watching, update
     */
    setFinishedGetting (type) {
      this.isGetting[type] = false
    },
    /**
     * コンディションダイアログの表示有無を設定
     * @param {Boolean} isOpen 表示するかどうか
     */
    setConditionDialog (isOpen) {
      this.openConditionDialog = isOpen
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.home {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  &__watching {
    margin-top: 10.6px;
  }
  &__update {
    margin-top: 10.6px;
  }
}
</style>
