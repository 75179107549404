<template>
  <div class="condition">
    <div class="condition__area" v-if="!isFinishedPage">
      <img class="condition__area__icon" src="@/assets/img/heartbeat.png">
      <h1 class="condition__area__title">Hôm nay bạn cảm thấy thế nào?</h1>
      <div class="condition__area__selected">
        <div class="condition__area__selected__item"
        v-for="n in Object.keys(conditions)" :key="n" @click="select(n)">
          <div :class="selected === Number(n) ? 'condition__area__selected__item__circle--active' : 'condition__area__selected__item__circle'">
            <div :class="selected === Number(n) ? 'condition__area__selected__item__circle__small--active' : 'condition__area__selected__item__circle__small'">{{ n }}</div>
          </div>
          <p class="condition__area__selected__item__text">{{ conditions[n] }}</p>
        </div>
      </div>
      <button :class="isSelected ? 'condition__area__next--active' : 'condition__area__next'"
      :disabled=!isSelected @click="send()">Tiếp</button>
    </div>
    <div class="condition__area--finished" v-else>
      <img class="condition__area__close" src="@/assets/img/close-white.svg" @click="$emit('setConditionDialog', false)">
      <img class="condition__area__icon" v-if="selected === 1" src="@/assets/img/cloud.png">
      <img class="condition__area__icon--lamp" v-else src="@/assets/img/lamp.svg">
      <h1 class="condition__area__title">{{ selected === 1 ? 'Bạn có đang gặp vấn đề gì không?' : 'Bắt đầu ứng dụng Hanabi nào !' }}</h1>
      <p class="condition__area__text">{{ selected === 1  ? 'Nếu gặp vấn đề gì trong cuộc sống hằng ngày, hãy tìm người lắng nghe và chia sẻ nhé. Hãy nhớ, bạn không bao giờ cô độc !' : 'Hãy xem những video liên quan đến sở thích của bạn, hoặc kiểm tra kỹ năng nói với các bài test hàng tuần. Chúng tôi ở đây để giúp bạn học tốt !' }}</p>
      <button class="condition__area__btn" @click="$emit('setConditionDialog', false)">Bắt đầu sử dụng Hanabi</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      // コンディション
      conditions: {
        1: 'Tồi tệ',
        2: 'Không tốt lắm',
        3: 'Bình thường',
        4: 'Tốt',
        5: 'Tuyệt vời!'
      },
      // 選択中のコンディション
      selected: 0,
      // 最後のページかどうか
      isFinishedPage: false
    }
  },
  mounted () {
    this.$emit('setConditionDialog', true)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Boolean} コンディションを選択中かどうか
     */
    isSelected () {
      return this.selected > 0
    }
  },
  methods: {
    ...mapActions('condition', ['createCondition']),
    /**
     * コンディションの選択
     * @param {Number} n 選択した数字
     */
    select (n) {
      this.selected === n ? this.selected = 0 : this.selected = Number(n)
    },
    /**
     * コンディション情報の送信
     */
    send () {
      this.createCondition({
        uid: this.uid,
        condition: this.selected,
        createdAt: new Date()
      }).then(() => {
        this.isFinishedPage = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.condition {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(74, 74, 74, 0.7);
  text-align: center;
  z-index: 100;
  &__area {
    position: relative;
    height: 300px;
    width: 90vw;
    margin: 0 auto;
    margin-top: calc((100vh - 300px) / 2);
    border-radius: 8px;
    background-color: white;
    &--finished {
      @extend .condition__area;
      height: 290px;
    }
    &__icon {
      position: absolute;
      top: -79.5px;
      left: calc((90vw - 176px) / 2);
      height: auto;
      width: 176px;
      &--lamp {
        @extend .condition__area__icon;
        top: -84px;
        left: calc((90vw - 77.5px) / 2);
        width: 77.5px;
      }
    }
    &__close {
      position: absolute;
      top: -65px;
      right: 0;
      height: 14.5px;
      width: 14.5px;
    }
    &__title {
      margin: 0;
      padding: 78px 10px 0 10px;
      font-size: 22px;
      font-weight: bold;
      color: #393939;
    }
    &__text {
      margin: 0;
      margin-top: 5px;
      padding: 0 16px;
      font-size: 12px;
      color: #393939;
    }
    &__selected {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      width: 100%;
      margin-top: 21px;
      padding: 0 2.3px;
      box-sizing: border-box;
      &__item {
        flex: 1;
        text-align: center;
        &__circle {
          height: 45.2px;
          width: 45.2px;
          margin: 0 auto;
          padding: 6.65px;
          border-radius: 50%;
          background-color: #d2d2d2;
          &--active {
            @extend .condition__area__selected__item__circle;
            background-color: #ffd9b6;
          }
          &__small {
            height: 31.9px;
            width: 31.9px;
            margin: 0 auto;
            color: white;
            font-size: 8px;
            font-weight: bold;
            line-height: 31.9px;
            text-align: center;
            border-radius: 50%;
            background-color: #a5a5a5;
            &--active {
              @extend .condition__area__selected__item__circle__small;
              background-color: #ff9534;
            }
          }
        }
        &__text {
          margin: 0;
          margin-top: 1.7px;
          font-size: 10px;
          font-weight: bold;
          color: #707070;
        }
      }
    }
    &__next {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 51px;
      width: 100%;
      font-size: 22px;
      line-height: 51px;
      color: white;
      text-align: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #a5a5a5;
      &--active {
        @extend .condition__area__next;
        background-color: #fec007;
      }
    }
    &__btn {
      height: 44px;
      width: 206px;
      margin-top: 18.4px;
      font-size: 17px;
      color: white;
      border-radius: 36px;
      background-color: #fec007;
    }
  }
}
</style>
