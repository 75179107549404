<template>
  <div class="update" v-if="!isGetting && Object.keys(updatedMovies).length > 0">
    <h2 class="update__title">Cập nhật</h2>
    <movie-slider :movies=updatedMovies></movie-slider>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import movieSlider from '@/components/home/movieSlider'

export default {
  components: { movieSlider },
  data () {
    return {
      // 最新動画の取得中か
      isGetting: true
    }
  },
  mounted () {
    // 最新動画が未取得の場合、取得する
    if (Object.keys(this.updatedMovies).length === 0) {
      this.addUpdatedMovies().then(() => {
        this.isGetting = false
        this.$emit('setFinishedGetting', 'update')
      })
    } else {
      this.isGetting = false
      this.$emit('setFinishedGetting', 'update')
    }
  },
  computed: {
    /**
     * @return {Object} 最新の動画一覧
     */
    updatedMovies () {
      return this.getUpdatedMovies()()
    }
  },
  methods: {
    ...mapGetters('movie', ['getUpdatedMovies']),
    ...mapActions('movie', ['addUpdatedMovies'])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.update {
  width: calc(100vw - #{$main_padding});
  &__title {
    margin: 0;
    color: #393939;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
