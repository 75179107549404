<template>
<ul class="slider">
  <li v-for="id in Object.keys(movies)" class="slider__area" :key=id>
    <router-link :to="{ name: 'VideoDetail', params: { id: id }, query: { uid: uid } }">
      <img class="slider__area__img" :src="movies[id].thumb" />
      <h2 class="slider__area__name--video">{{ movies[id].name }}</h2>
      <h3 class="slider__area__name--teacher">{{ getTeacher()(movies[id].teacher).name }}</h3>
    </router-link>
  </li>
</ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // 動画オブジェクト
    // { id: オブジェクト, ... }
    movies: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    }
  },
  methods: {
    ...mapGetters('teacher', ['getTeacher'])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.slider {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
  padding-left: 0;
  &::-webkit-scrollbar{
    display:none;
  }
  &__area {
    position: relative;
    display: inline-block;
    width: 263.1px;
    margin-right: 16px;
    &__img {
      height: 148px;
      width: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
    &__name {
      width: 100%;
      margin: 0;
      font-size: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &--video {
        @extend .slider__area__name;
        font-weight: bold;
        color: #393939;
      }
      &--teacher {
        @extend .slider__area__name;
        font-size: 12px;
        font-weight: normal;
        color: #A5A5A5;
      }
    }
  }
}
</style>
