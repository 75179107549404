<template>
  <div class="watching" v-if="!isGetting && Object.keys(watchingMovies).length > 0">
    <h2 class="watching__title">Đang theo dõi</h2>
    <movie-slider :movies=watchingMovies></movie-slider>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import movieSlider from '@/components/home/movieSlider'

export default {
  components: { movieSlider },
  data () {
    return {
      // 再生中のオブジェクト一覧
      // { id: 動画オブジェクト, ... }
      watchingMovies: {},
      // 再生中の動画を取得中か
      isGetting: true
    }
  },
  mounted () {
    // 毎更新のたびに取得
    // 再生中の動画IDを取得し、その後動画自体を取得
    this.getWatchingMovieIDs(this.uid).then(mids => {
      this.addWatchingMovies(mids.slice(0, 5)).then(watchingMovies => {
        this.watchingMovies = watchingMovies
        this.isGetting = false
        this.$emit('setFinishedGetting', 'watching')
      })
    })
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    }
  },
  methods: {
    ...mapActions('watchingMovie', ['getWatchingMovieIDs']),
    ...mapGetters('movie', ['getWatchingMovies']),
    ...mapActions('movie', ['addWatchingMovies'])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.watching {
  width: calc(100vw - #{$main_padding});
  &__title {
    margin: 0;
    color: #393939;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
