<template>
  <div class="pickup" v-if="!isGetting">
    <h2 class="pickup__title">Cho bạn</h2>
    <h3 class="pickup__subtitle">Dựa trên sở thích của bạn</h3>
    <movie-slider :movies="getDisplayPickupMovies()"></movie-slider>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import movieSlider from '@/components/home/movieSlider'

const MAX_DISPLAY_NUM = 5

export default {
  components: { movieSlider },
  data () {
    return {
      // 動画を取得中かどうか
      isGetting: true
    }
  },
  mounted () {
    // ピックアップ動画の候補が存在するか
    // 存在しない場合は、候補を取得する
    if (Object.keys(this.pikupMovies).length === 0) {
      if (this.occupation === undefined && this.user.occupation.length > 0) {
        this.$store.dispatch('occupation/getOccupation', this.user.occupation).then(name => {
          this.addPickupMovies({
            level: this.user.level,
            occupation: name
          }).then(() => {
            this.isGetting = false
            this.$emit('setFinishedGetting', 'pickup')
          })
        })
      } else {
        this.addPickupMovies({
          level: this.user.level,
          occupation: this.occupation
        }).then(() => {
          this.isGetting = false
          this.$emit('setFinishedGetting', 'pickup')
        })
      }
    } else {
      this.isGetting = false
      this.$emit('setFinishedGetting', 'pickup')
    }
  },
  computed: {
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['user/user']
    },
    /**
     * @return {Object} 職業情報
     */
    occupation () {
      return this.$store.getters['occupation/occupation'](this.user.occupation)
    },
    /**
     * @return {Object} ピックアップ動画（最大5件）
     */
    pikupMovies () {
      return this.getPickupMovies()()
    }
  },
  methods: {
    ...mapGetters('movie', ['getPickupMovies']),
    ...mapActions('movie', ['addPickupMovies']),
    /**
     * @return {Object} 候補からランダムに取得したものを返却
     */
    getDisplayPickupMovies () {
      const results = {}
      const length = Object.keys(this.pikupMovies).length // 候補の動画数
      while (Object.keys(results).length < (length < MAX_DISPLAY_NUM ? length : MAX_DISPLAY_NUM)) {
        // ランダムにキーを取得して、格納していく
        const key = Object.keys(this.pikupMovies)[Math.floor(Math.random() * Object.keys(this.pikupMovies).length)]
        results[key] = this.pikupMovies[key]
      }
      return results
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.pickup {
  width: calc(100vw - #{$main_padding});
  &__title {
    margin: 0;
    color: #393939;
    font-size: 20px;
    font-weight: bold;
  }
  &__subtitle {
    margin: 0;
    color: #A5A5A5;
    font-size: 15px;
    font-weight: normal;
  }
}
</style>
